@font-face {
    font-family: "FuturaPT-Book";
    src: local("FuturaPT-Book"),
        url("../../assets/fonts/FuturaPT-Book.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Bold";
    src: local("FuturaPT-Bold"),
        url("../../assets/fonts/FuturaPT-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Demi";
    src: local("FuturaPT-Demi"),
        url("../../assets/fonts/FuturaPT-Demi.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Light";
    src: local("FuturaPT-Light"),
        url("../../assets/fonts/FuturaPT-Light.ttf") format("truetype");
    font-weight: bold;
}

.pos{
    flex: 8;
    display: flex;
    flex-direction: row;
}

.posWrapper {
    flex: 8;
    background: #92B5C2;
}

.posContainer {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto
}

.userContainer {
    flex: 2;
    background: #CCCCCC;
}

.homeWidgets{
    display: flex;
    margin: 20px;
}

.posImg {
    height: 10vh;
    width: 10vh;
}

.posItem {
    text-align: center;
    height: 15vh;
    width: 15vh;
    margin: 5px;
    padding: 5px;
    background: gray;
    cursor: pointer;
}

.calculator {
    display: grid;
}

.cartContainer {
    display: flex;
}

.cartItem {
    width: 33.33%;
    padding: 2px;
    background: white;
    border: 0.5px solid lightgray;
    border-radius: 1px;
    font-family: FuturaPT-Book!important;
}

.quantityItem {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 33.33%;
    padding: 2px;
    background: white;
    border: 0.5px solid lightgray;
    border-radius: 1px;
    font-family: FuturaPT-Book!important;
}

.cartTitle {
    width: 33.33%;
    padding: 2px;
    background: BLACK;
    border: 0.5px solid lightgray;
    color:white;
    border-radius: 1px;
    font-family: FuturaPT-Demi!important;
}

.summary {
    display: flex;
    padding: 10px 0 5px 0px;
    background: rgb(240, 240, 255);
    justify-content:space-around;
    padding: 15px 0;
}

.depositPadding {
    padding: 20px;
}

.summarytotal {
    font-family: FuturaPT-Demi!important;
    font-size: 1.2rem;
}

.summarysubtotal {
    font-family: FuturaPT-Demi!important;
    font-size: 1rem;
    text-align: center;
}

.usersummaryContainer {
    height: 60vh;
    background: rgb(240, 240, 255);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
}

.discountContainer {
    height: 10vh;
    background: rgb(240, 240, 255);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.addDiscountItem {
    width: 100%;
    grid-template-columns: 50% 50%;
    display: grid;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
  }
  
  .black {
    color: black;
    font-weight: 600;
  }


.checkoutButtons {
    display: flex;
    background: #B44144;
    justify-content:space-around;
}

.giftcard {
    background: #4144b4!important;
}

.totalButton {
    display:flex;
    width:100%;
    align-items:center;
    text-align: center;
        font-family: FuturaPT-Demi!important;
    font-size: 1.2rem;
}

.salesButton {
    padding: 20px 0;
    display:flex;
    width:100%;
    justify-content: center;
    text-align: center;
    background: #800040;
    cursor: pointer;
    font-family: FuturaPT-Demi!important;
    font-size: 1.2rem;


}

.sales {
    background: #004080;
    color:white;
    font-family: FuturaPT-Bold!important;
}

.sales:hover {
    background: #800040;
    color:white;
}

.reset {
    background: #445101;
    color:white;
    font-family: FuturaPT-Bold!important;
}

.reset:hover {
    background: #510144;
    color:white;
}

.deposit {
    background: #510144;
    color:white;
    font-family: FuturaPT-Bold!important;
}

.deposit:hover {
    background: #445101;
    color:white;
}

.warrantyContainer {
    display: flex;
    justify-content: center;
    background: black;
    color: white;
}

.completeContainer {
    display: flex;
    justify-content: center;
    background: black;
    color: white;
}

.warrantyItem {
    width: 50%;
    text-align:center;
    padding: 2rem 0;
}

.black {
    color: black!important;
}

.paddingX {
    padding:2rem 1.5rem;
}

.pointer{
   cursor: pointer;
}

.beige {
    background: #f0fff0!important;
    border:1px solid black;
}

.noBorder {
    border: 0px!important;
}

.lightred {
    background: 	#fff0f0!important;
    border:1px solid black;
}

.lightblue {
    background: #f0f8ff!important;
    border:1px solid black;
}

.lightyellow {
    background: #f8fff0!important;
    border:1px solid black;
}

.darkyellow {
    background: #445101!important;
}

.darkpurple {
    background: #510144!important;
}
