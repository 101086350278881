.sameRowContainer {
    display: grid;
   grid-template-columns:60%  40%;
    padding: 1rem 0;
}

.graphSameRowContainer {
  display: grid;
 grid-template-columns:35% 65%;
 padding: 3rem 0;
 
}

.graphFullRowContainer {
  display: grid;
 grid-template-columns:95%;
 padding: 3rem 0;
 
}

.reportContainer {
    margin: 20px 0;
    color: white;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33% ;
    text-align:center;
}

.featuredReport {
    flex: 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border: 1px solid black;
    background: #771d1d;
    cursor: auto;
    margin-bottom: 20px;
  }
  
  .featuredTitle{
      font-size: 20px;
  }
  
  .featuredReportContainer{
      margin: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .featuredMoney{
      font-size: 30px;
      font-weight: 600;
  }
  
  .featuredMoneyRate{
      display: flex;
      align-items: center;
      margin-left: 20px;
  }
  
  .featuredIcon{
      font-size: 14px;
      margin-left: 5px;
      color: green;
  }
  
  .featuredIcon.negative{
      color: red;
  }
  
  .featuredSub{
      font-size: 15px;
      color: gray;
  }

  .inputFilter {
    margin-right: 2vh;
    width: 95%;
    margin-bottom: 10px;
    border: 1px solid black;
  }

  .selectFilter {
    margin-right: 2vh;
    width: 158px;
  }

  .buttonTop {
    width: 25%;
    margin-bottom: 10px;
    min-width: 3vw;
  }

  .grid {
    display: grid;
    margin-right: 30px;
    grid-template-columns: 50% 50%;
    grid-gap: 5px;
  }

  .red {
    background-color: #510e01!important;
  }

  .blue {
    background-color: #362f78!important;
  }

  .green {
    background-color: #014451!important;
  }

  .yellow {
    background-color: #445101!important;
  }

  .selectionWidth {
    width: 10vw;
    margin-right: 2vh;
}

.warrantyWidth {
  width: 14vh;
}

.itemItem {
  margin-bottom: 10px;
}

@media (max-width:480px)  { 
  .graphSameRowContainer {
    display: grid;
   grid-template-columns:100%;
   padding: 2rem;
   
  }

  .buttonTop {
    width: 50vw;
    margin-bottom: 10px;
    min-width: 3vw;
  }

  .sameRowContainer {
    display: grid!important;
   grid-template-columns:100%;
    padding: 1rem 0;
}

.inputFilter {
  margin-right: 2vh;
  width: 100%;
  margin-bottom: 1px;
  
}

.reportContainer {
  grid-template-columns: 100%
}

}